const categoryColors = {
  "Tech Culture": "rgb(255,59,48)",
  "Tech News": "rgb(0,113,164)",
  "Personal Betterment": "rgb(255,45,85)",
  Vue: "rgb(62,199,89)",
  React: "rgb(64,156,255)",
  JavaScript: "rgb(255,179,64)",
  Cloud: "rgb(175,82,250",
};

export { categoryColors };
